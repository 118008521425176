<template>
  <b-card title="我发起的">
    <b-list-group v-if="approvals.length">
    <b-list-group-item
      v-for="approval in approvals"
      class="flex-column align-items-start"
      >
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1 text-white">
          {{ approval.time_cn }}
        </h5>

        <span>
          <b-badge :variant="mapStatusVariant(approval.status)">
            {{ mapStatus(approval.status) }}
          </b-badge>
        </span>
      </div>

      <b-card-text class="mb-1">
        <small v-for="(key,index) in Object.keys(approval.approval_data)" v-if="index <= 1">
          {{ key }} : {{ approval.approval_data[key] }}        <br>
        </small>
      </b-card-text>

      <b-card-text class="mb-1" >
        <span>
          <b-button         
            v-b-modal.modal-detail
            variant="primary"
            size="sm"
            @click="checkDetail(approval.launched_id)">
            查看
          </b-button>

          <b-button 
            v-if="approval.status == 'pending'" variant="outline-danger" size="sm" class="ml-1" @click="cancelLaunched(approval.launched_id)">
            撤销
          </b-button>
        </span>
      </b-card-text>

      <b-card-text class="mb-1" >
        <span>
        </span>
      </b-card-text>
    </b-list-group-item>
  </b-list-group>

  <div class="d-flex w-100 justify-content-center" v-else>
    <p>
      暂无数据
    </p>
  </div>

    <b-modal
      centered
      ok-only
      ok-title="关闭"
      id="modal-detail"
      :title="checkingApproval.time_cn"
      cancel-variant="outline-secondary"
    >
    <b-card-text v-if="checkingApproval.project_id">
        <b-badge variant="light-primary"
        href="#"
        @click="goToProject(checkingApproval.project_id)">
          <feather-icon
              icon="LinkIcon"
              class="mr-25"
            />
          {{ checkingApproval.project_id }}
        </b-badge>
      </b-card-text>
      <b-card-text
        v-for="i in Object.keys(checkingApprovalData)">
        <span class="mr-1">{{ i }}：</span>
        <span >{{ checkingApprovalData[i] }}</span>
      </b-card-text>

      <hr>

      <b-card-text>
        <app-timeline v-if="Object.keys(checkingApproval.approval_data).length">
          <app-timeline-item
            v-for="workflow in checkingApproval.workflow_status"
            :variant="mapTimelineVariant(workflow.id)"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>{{ workflow.name }}</h6> <small>{{ workflow.time_cn }}</small>
            <!--  <small class="text-muted">12 min ago</small> -->
            </div>
            <b-badge variant="light-primary" v-for="member in workflow.members" class="mr-1">
              {{mapUserName(member)}}
            </b-badge>
            <small>
              <template v-if="mapNote(workflow.id)">
                备注：{{ mapNote(workflow.id) }}
              </template>

              <template v-if="mapNote(workflow.id) && mapDeliveryCode(workflow.id)">
                ；
              </template>

              <template v-if="mapDeliveryCode(workflow.id)">
                快递单号：{{ mapDeliveryCode(workflow.id) }}
              </template>
            </small>
          </app-timeline-item>
        </app-timeline>
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BMedia, BAvatar, BListGroup, BListGroupItem, BCardText ,BBadge , BButton ,BModal, VBModal
} from 'bootstrap-vue'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    BListGroup, 
    BListGroupItem,
    BCardText,
    BBadge,
    BButton,
    BModal,
    AppTimeline,
    AppTimelineItem
  },
  directives: {
    'b-modal': VBModal,
  },
  props:{
    systemLabel: {
      type: String,
      default: ""
    },
    approvalType: {
      type: String,
      default: ""
    },
    approvals: {
      type: Array,
      default: () => ([])
    },
    workflows: {
      type: Array,
      default: () => ([])
    },
    users: {
      type: Array,
      default: () => ([])
    }
  },
  data(){
    return{
      checkingApproval: {
        approval_data: {}
      },
    }
  },
  computed:{
    checkingApprovalData: function(){
      var data = JSON.parse(JSON.stringify(this.checkingApproval.approval_data))

      Object.keys(data).forEach((control) => {
        if(typeof(data[control]) != "string"){
          if(!Array.isArray(data[control])){
            data[control] = data[control]["value"]
          }else{
            var temp = []
            data[control].forEach((item) => {
              temp.push(item["value"])
            })
            data[control] = temp.join("；")
          }
      }
      })
      return data
    }
  },
  methods:{
    goToProject(projectId){
      this.$router.push({name: 'collab-pm-view', params: {id: projectId}})
    },
    checkDetail(launchedId){
      this.$axios.get('collab/approvals/get_launched_approval/' + launchedId).then((response) => {
        this.checkingApproval = response.data.data.launched_detail
      })
    },
    cancelLaunched(id){
      this.$axios.get('collab/approvals/cancel_launched_approval/' + id).then((response) => {
        if(response.data.status == "ok"){
          this.$emit('get-approvals')
        }
      })
    },
    mapTimelineVariant(workflowId){
      var workflow = this.checkingApproval.workflow_status.find((workflow) => {
        return workflow.id == workflowId
      })
      if(workflow.status == "pending"){
        var v = "primary"
      }else if(workflow.status == "approved"){
        var v = "success"
      }else if(workflow.status == "rejected"){
        var v = "danger"
      }else if(workflow.status == null){
        var v = null
      }
      return v
    },
    mapUserName(userId){
      var user = this.users.find((user) => {
        return user.user_id == userId
      })
      return user.user_name
    },
    mapStatusVariant(status){
      if(status == "pending"){
        var v = "light-primary"
      }else if(status == "approved"){
        var v = "light-success"
      }else if(status == "rejected"){
        var v = "light-danger"
      }else if(status == "canceled"){
        var v = "light-secondary"
      }
      return v
    },
    mapStatus(status){
      if(status == "pending"){
        return "待审批"
      }else if(status == "approved"){
        return "已通过"
      }else if(status == "rejected"){
        return "已驳回"
      }else if(status == "canceled"){
        return "已撤消"
      }
  },
  mapNote(workflowId){
      var workflow = this.checkingApproval.workflow_status.find((workflow) => {
        return workflow.id == workflowId
      })
      if(workflow.note){
        return workflow.note
      }else{
        return false
      }
    },
  mapDeliveryCode(workflowId){
    var workflow = this.checkingApproval.workflow_status.find((workflow) => {
        return workflow.id == workflowId
      })
      if(workflow.delivery_code){
        return workflow.delivery_code
      }else{
        return false
      }
  }
}
}
</script>

<style>

</style>
