<template>
  <b-card title="我负责的">
    <b-tabs pills>
      <b-tab title="待处理">
        <b-list-group v-if="approvals.length">
          <b-list-group-item
            v-for="approval in myPendingApprovals"
            class="flex-column align-items-start"
            >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1 text-white">
                {{ mapUserName(approval.launch_user) }} {{ approval.time_cn }} 
              </h5>

              <span>
                <b-badge v-if="approval.status=='pending'" :variant="mapStatusVariant(approval.workflow_status)" >
                  {{ mapLiableStatus(approval.workflow_status) }}
                </b-badge>

                <b-badge v-else-if="approval.status=='approved'" variant="light-success" >
                  已通过
                </b-badge>

                <b-badge v-else-if="approval.status=='canceled'" variant="light-secondary" >
                  已撤销
                </b-badge>

                <b-badge v-else-if="approval.status=='rejected'" variant="light-danger" >
                  已驳回
                </b-badge>

              </span>
            </div>

              <b-card-text class="mb-1">
                <small v-for="(key,index) in Object.keys(approval.approval_data)" v-if="index <= 1">
                  {{ key }} : {{ approval.approval_data[key] }}<br>
                </small>
              </b-card-text>

            <b-card-text class="mb-1" >
              <span>
                <b-button         
                  v-b-modal.modal-liable-detail
                  variant="primary" 
                  size="sm" 
                  @click="checkDetail(approval.launched_id)">
                  查看
                </b-button>
              </span>
            </b-card-text>
          </b-list-group-item>
        </b-list-group>
        <div class="d-flex w-100 justify-content-center" v-else>
          <p>暂无数据</p>
        </div>
      </b-tab>
      <b-tab title="已处理">

        <b-list-group v-if="approvals.length">
          <b-list-group-item
            v-for="approval in solvedApprovals"
            class="flex-column align-items-start"
            >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1 text-white">
                {{ mapUserName(approval.launch_user) }} {{ approval.time_cn }} 
              </h5>

              <span>
                <template v-if="approval.systemLabel == 'INVOICE_APPROVAL'">
                <b-badge v-if="approval.status =='approved' && approval.payment_status" variant="light-success" class="mr-1">
                   已回款 
                </b-badge>

                <b-badge v-else-if="approval.status =='approved' && !approval.payment_status" variant="light-warning" class="mr-1">
                   未回款 
                </b-badge>
              </template>

                <b-badge v-if="approval.status=='pending'" :variant="mapStatusVariant(approval.workflow_status)" >
                  {{ mapLiableStatus(approval.workflow_status) }}
                </b-badge>

                <b-badge v-else-if="approval.status=='approved'" variant="light-success" >
                  已通过
                </b-badge>

                <b-badge v-else-if="approval.status=='canceled'" variant="light-secondary" >
                  已撤销
                </b-badge>

                <b-badge v-else-if="approval.status=='rejected'" variant="light-danger" >
                  已驳回
                </b-badge>

              </span>
            </div>

              <b-card-text class="mb-1">
                <small v-for="(key,index) in Object.keys(approval.approval_data)" v-if="index <= 1">
                  {{ key }} : {{ approval.approval_data[key] }}<br>
                </small>
              </b-card-text>

            <b-card-text class="mb-1" >
              <span>
                <b-button         
                  v-b-modal.modal-liable-detail
                  variant="primary" 
                  size="sm" 
                  @click="checkDetail(approval.launched_id)">
                  查看
                </b-button>
              </span>
            </b-card-text>
          </b-list-group-item>
        </b-list-group>
        <div class="d-flex w-100 justify-content-center" v-else>
          <p>暂无数据</p></div>
      </b-tab>
    </b-tabs>

  
    <b-modal 
      centered
      :ok-only="!isMyWorkflow"
      :ok-title="isMyWorkflow ? '通过' : '关闭'"
      cancel-title="驳回"
      id="modal-liable-detail"
      :title="checkingApproval.time_cn"
      :ok-variant="isMyWorkflow ? 'success' : 'primary'"
      cancel-variant="outline-danger"
      @ok="isMyWorkflow ? approveConfirm() : null"
      @cancel="rejectConfirm"
    >
      <b-card-text v-if="checkingApproval.project_id">
        <b-badge variant="light-primary"
        href="#"
        @click="goToProject(checkingApproval.project_id)">
          <feather-icon
              icon="LinkIcon"
              class="mr-25"
            />
          {{ checkingApproval.project_id }}
        </b-badge>
      </b-card-text>

      <b-card-text
        v-for="i in Object.keys(checkingApprovalData)">
        <span class="mr-1">{{ i }}：</span>
        <span >{{ checkingApprovalData[i] }}</span>
      </b-card-text>
      <hr>
      <b-card-text>
        <app-timeline v-if="Object.keys(checkingApproval.approval_data).length">
          <app-timeline-item
            v-for="workflow in checkingApproval.workflow_status"
            :variant="mapTimelineVariant(workflow.id)"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>{{ workflow.name }}</h6>               <small>{{ workflow.time_cn }}</small>

              <!-- <small class="text-muted">12 min ago</small> -->
            </div>

            <b-badge v-for="member in workflow.members" class="mr-1" :variant="mapMemberVariant(member,workflow.id)">
              {{mapUserName(member)}}
            </b-badge>


            <small>
              <template v-if="mapNote(workflow.id)">
                备注：{{ mapNote(workflow.id) }}
              </template>

              <template v-if="mapNote(workflow.id) && mapDeliveryCode(workflow.id)">
                ；
              </template>

              <template v-if="mapDeliveryCode(workflow.id)">
                快递单号：{{ mapDeliveryCode(workflow.id) }}
              </template>
            </small>
          </app-timeline-item>
        </app-timeline>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-reject"
      cancel-variant="outline-secondary"
      ok-variant="danger"
      ok-title="驳回"
      cancel-title="关闭"
      centered
      title="驳回审批流"
      @ok="reject"
      @hide="note = ''"
    >
      <b-form>
        <b-form-group>
          <label for="note">备注:</label>
          <b-form-input
            v-model="note"
            id="note"
            type="text"
            placeholder=""
          />
        </b-form-group>
       
      </b-form>
    </b-modal>

    <b-modal
      id="modal-approve"
      cancel-variant="outline-secondary"
      ok-variant="success"
      ok-title="通过"
      cancel-title="关闭"
      centered
      title="通过审批流"
      @ok="approve"
      @hidden="note = ''"
    >
      <b-form>
        <b-form-group>
          <label for="note">备注:</label>
          <b-form-input
            v-model="note"
            id="note"
            type="text"
            placeholder=""
          />
        </b-form-group>

        <b-form-group v-if="deliveryCodeRequired">
          <label for="delivery-code">快递单号:</label>
          <b-form-input
            v-model="deliveryCode"
            id="delivery-code"
            type="text"
            placeholder=""
          />
        </b-form-group>
       
      </b-form>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BMedia, BAvatar, BListGroup, BListGroupItem, BCardText ,BBadge , BButton ,BModal, VBModal,BForm,BFormGroup,BFormInput, BTab,BTabs
} from 'bootstrap-vue'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    BListGroup, 
    BListGroupItem,
    BCardText,
    BBadge,
    BButton,
    BModal,
    AppTimeline,
    AppTimelineItem,BForm,BFormGroup,BFormInput,BTab,BTabs
  },
  directives: {
    'b-modal': VBModal,
  },
  props:{
    approvals: {
      type: Array,
      default: () => ([])
    },
    workflows: {
      type: Array,
      default: () => ([])
    },
    users: {
      type: Array,
      default: () => ([])
    },
    approvalType: {
      type: String,
      default: ""
    },
    systemLabel: {
      type: String,
      default: ""
    }
  },
  data(){
    return{
      checkingApproval: {
        approval_data: {}
      },
      note: "",
      deliveryCode:"",
    }
  },
  computed:{
    isMyWorkflow(){
      
      if(!Object.keys(this.checkingApproval.approval_data).length) return false
      else if(this.checkingApproval.status != "pending") return false

      var pendingWorkflow = this.checkingApproval.workflow_status.find(w => w.status == "pending")
      if(!pendingWorkflow) return false
      
      var myUserId = JSON.parse(localStorage.getItem("userData")).userId
      if (pendingWorkflow.member_status.pending.includes(myUserId)) {
        return true
      } else{
        return false
      }
    },
    checkingApprovalData: function(){
      var data = JSON.parse(JSON.stringify(this.checkingApproval.approval_data))
      Object.keys(data).forEach((control) => {
        if(typeof(data[control]) != "string"){
          if(!Array.isArray(data[control])){
            data[control] = data[control]["value"]
          }else{
            var temp = []
            data[control].forEach((item) => {
              temp.push(item["value"])
            })
            data[control] = temp.join("；")
          }
      }
      })
      return data
    },
    deliveryCodeRequired(){
      if(this.systemLabel != 'INVOICE_APPROVAL') return false

      if(!Object.keys(this.checkingApproval.approval_data).length) return false
      else if(this.checkingApproval.status != "pending") return false

      var pendingWorkflow = this.checkingApproval.workflow_status.find(w => w.status == "pending")
      if(!pendingWorkflow) return false

      var lastWorkflow = this.checkingApproval.workflow_status[this.checkingApproval.workflow_status.length - 1]
      if(pendingWorkflow.id == lastWorkflow.id){
        return true
      }else{
        return false
      } 
    },
    myPendingApprovals(){
      var myPendingApprovals = []
      var myUserId = JSON.parse(localStorage.getItem("userData")).userId
      this.approvals.forEach(a => {
        a.workflow_status.forEach(w => {
          if(w.status == "pending"){
            if (w.member_status.pending.includes(myUserId)) {
              myPendingApprovals.push(a)
            }
          }
        })        
      })
      return myPendingApprovals
    },
    solvedApprovals(){
      var solvedApprovals = []
      this.approvals.forEach(a => {
        if(!this.myPendingApprovals.includes(a)){
          solvedApprovals.push(a)
        }
      })
      return solvedApprovals
    },
  },
  methods:{
    goToProject(projectId){
      this.$router.push({name: 'collab-pm-view', params: {id: projectId}})
    },
    checkDetail(launchedId){
      this.$axios.get('collab/approvals/get_launched_approval/' + launchedId).then((response) => {
        this.checkingApproval = response.data.data.launched_detail
      })
    },
    rejectConfirm(){
      this.$bvModal.show('modal-reject')
    },
    reject(){
      var postData = {
        launched_id: this.checkingApproval.launched_id,
        note: this.note
      }
      this.$axios.post('/collab/approvals/reject',postData).then(resp =>{
        if(resp.data.status == "ok"){
          this.$emit("get-approvals")
        }
      })
    },
    approveConfirm(){
      this.$bvModal.show('modal-approve')
    },
    approve(){
      if (this.deliveryCodeRequired) {
        if (!this.deliveryCode){
          this.$toast.error('请填写快递单号')
          return
        }
      }
      var postData = {
        launchedId: this.checkingApproval.launched_id,
        note: this.note,
        deliveryCode: this.deliveryCode
      }
      this.$axios.post('collab/approvals/approve',postData).then((response) => {
          if(response.data.status == "ok"){
            this.$emit("get-approvals")
          }
        })
    },
    mapTimelineVariant(workflowId){
      var workflow = this.checkingApproval.workflow_status.find((workflow) => {
        return workflow.id == workflowId
      })

      if(workflow.status == "pending"){
        var v = "primary"
      }else if(workflow.status == "approved"){
        var v = "success"
      }else if(workflow.status == "rejected"){
        var v = "danger"
      }else if(workflow.status == null){
        var v = null
      }
      return v
    },
    mapMemberVariant(user,workflowId){
      var workflow = this.checkingApproval.workflow_status.find((workflow) => {
        return workflow.id == workflowId
      })
      if(workflow.member_status.approved.includes(user)){
        var v = "light-success"
      }else if(workflow.member_status.rejected.includes(user)){
        var v = "light-danger"
      }else if(workflow.member_status.pending.includes(user)){
        var v = "light-primary"
      }
      return v
    },
    mapUserName(userId){
      var user = this.users.find((user) => {
        return user.user_id == userId
      })
      return user.user_name
    },
    mapLiableStatus(workflowStatus){
      var pendingWorkflow = workflowStatus.find(w => w.status == "pending")

      var myUserId = JSON.parse(localStorage.getItem("userData")).userId

      if (pendingWorkflow.member_status.approved.includes(myUserId)) {
        return "当前审批流我已通过"
      } else if(pendingWorkflow.member_status.rejected.includes(myUserId)) {
        return "当前审批流我已驳回"
      } else if(pendingWorkflow.member_status.pending.includes(myUserId)) {
        return "当前审批流待我审批"
      } else{
        return "当前审批流等待其他人审批"
      }
    },
    mapStatusVariant(workflowStatus){
      var pendingWorkflow = workflowStatus.find(w => w.status == "pending")

      var myUserId = JSON.parse(localStorage.getItem("userData")).userId

      if (pendingWorkflow.member_status.approved.includes(myUserId)) {
        return "light-success"
      } else if(pendingWorkflow.member_status.rejected.includes(myUserId)) {
        return "light-danger"
      } else if(pendingWorkflow.member_status.pending.includes(myUserId)) {
        return "light-primary"
      } else{
        return "light-secondary"
      }
    },
    mapNote(workflowId){
      var workflow = this.checkingApproval.workflow_status.find((workflow) => {
        return workflow.id == workflowId
      })
      if(workflow.note){
        return workflow.note
      }else{
        return false
      }
    },
    mapDeliveryCode(workflowId){
    var workflow = this.checkingApproval.workflow_status.find((workflow) => {
        return workflow.id == workflowId
      })
      if(workflow.delivery_code){
        return workflow.delivery_code
      }else{
        return false
      }
  }
}
}
</script>

<style>

</style>
