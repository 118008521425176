<template>
  <b-sidebar
    id="approval-launch-sidebar"
    :visible="isApprovalLaunchSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-approval-launch-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          发起新审批
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <validation-observer
          ref="refFormObserver"
        >
        <b-form
          class="p-2"
          @submit.prevent="saveLaunchApproval()"
          @reset.prevent="clearForm">
            <validation-provider v-for="control in controls"
              #default="{ errors }"
              :name="control.name"
              :rules="mapRules(control.type,control.required)"
            >
              <b-form-group
                :label="control.name"
                :label-for="control.name"
                :state="errors.length > 0 ? false:null"
              >

                <b-form-input
                  v-if="control.type != `singleSelect` && control.type != `multiSelect`"
                  v-model="newApproval[control.name]"
                  :id="control.id"
                  @wheel.prevent
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :state="errors.length > 0 ? false:null"
                />

                <v-select
                  v-else
                  v-model="newApproval[control.name]"
                    :id="control.id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="control.options.map(option => ({ text: option, value: option }))"
                    :multiple="control.type == `multiSelect`"
                    label="text"
                />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isSaving"
            >保存
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              取消
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BSpinner,BBadge,BFormRadio,BFormDatepicker
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required,numeric,regex } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { json } from 'body-parser'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BBadge,
    BFormRadio,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isApprovalLaunchSidebarActive',
    event: 'update:is-approval-launch-sidebar-active',
  },
  props: {
    controls: {
      type: Array,
      required: true,
      default: () => [],
    },
    isApprovalLaunchSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isSaving:false,
      required,
      numeric,
      regex,
      newApproval: {},
    }
  },
  methods:{
    mapRules(type,required){
      let rules = {}
      if(required) rules.required = true
      if(type == "number") {
        rules.numeric = true
      }else if(type == "price"){
        let regex = new RegExp("^[0-9]+(.[0-9]{1,2})?$")
        rules.regex = regex
      }
      return rules
    },
    saveLaunchApproval(){
      this.$refs.refFormObserver.validate().then((result) => {
        if (result) {
          this.isSaving = true
          this.newApproval.approvalId = this.$route.params.id
          this.$axios.post('/collab/approvals/launch_approval',this.newApproval).then((resp) => {
            if (resp.data.status=="ok"){
              this.clearForm()
              this.$emit('update:is-approval-launch-sidebar-active', false)
              this.$emit('get-approvals')
            }
            this.isSaving = false
          })
        }
      })
    },
    clearForm(){
      this.newApproval = {}
      this.$refs.refFormObserver.reset()
    },

  },
  watch:{
    "isApprovalLaunchSidebarActive":function(val){
      if(val){
        this.controls.forEach(control => {
          this.$set(this.newApproval,control.name,control.type == `multiSelect`?[]:``)
        })
      }
    }
  },

  setup(props, { emit }) {
    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      country: '',
      contact: '',
    }
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const onSubmit = () => {
      store.dispatch('app-user/addUser', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
