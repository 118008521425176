<template>
  <b-card title="审批流模板">
    <b-list-group>
    <b-list-group-item
      v-for="control in controls"
    class="flex-column align-items-start"
    >
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1 text-white">
          {{ control.name }}
        </h5>
        <span>
          <b-badge variant="light-warning" v-if="control.required" class="mr-1">必填</b-badge>
          <b-badge variant="light-primary">{{mapControlText(control.type)}}</b-badge>
        </span>
      </div>
      <b-card-text class="mb-1" v-if="control.options.length">
        <span>
          <small>选项：</small>
          <small v-for="option in control.options">{{ option }}<small class="text-secondary">；</small></small>
        </span>      
      </b-card-text>

      <b-card-text class="mb-1" v-if="control.description">
        <span>
          <small>控件描述：</small>
          <small>{{ control.description }}</small>
        </span>
      </b-card-text>
    </b-list-group-item>
  </b-list-group>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BMedia, BAvatar, BListGroup, BListGroupItem, BCardText ,BBadge
} from 'bootstrap-vue'


export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    BListGroup, 
    BListGroupItem,
    BCardText,
    BBadge
  },
  props:{
    controls: {
      type: Array,
      default: () => ([])
    }
  },
  methods:{
    mapControlText(type){
      switch(type){
        case "text":
          return "文本"
        case "number":
          return "整数"
        case "price":
          return "金额"
        case "date":
          return "日期"
        case "singleSelect":
          return "单选"
        case "multiSelect":
          return "多选"
      }
    },
  }
}
</script>

<style>

</style>
