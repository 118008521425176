<template>
  <b-card>
    <b-row>
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ approvalData.approval_name }}
              </h4>
              <span class="card-text">{{ approvalData.approval_description }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                variant="primary"
                @click="launchApproval"
                v-if="approvalData.approval_type === 'custom'"
              >
                发起新审批
              </b-button>

<!--               <b-alert
                :show="approvalData.approval_type === 'system'"
                variant="warning"
              >
                <div class="alert-body">
                  <span class="ml-25">{{ alertContent }}</span>
                </div>
              </b-alert>
 -->
              <b-button
                :to="{ name: 'collab-approval-edit-view', params: { id: this.$route.params.id } }"
                variant="primary"
                class="ml-1"
              >
                编辑
              </b-button>


              <b-button
                v-if="approvalData.approval_type === 'custom'"
                @click="deleteApproval"
                variant="outline-danger"
                class="ml-1"
              >
                删除
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr v-if="approvalData.approval_type=='custom'">
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">创建人</span>
            </th>
            <td class="pb-50">
              <b-badge variant="light-primary">
              {{ mapUserName(approvalData.creator) }}
              </b-badge>
            </td>
          </tr>
          <tr  v-if="approvalData.approval_type=='custom'">
            <th class="pb-50">
              <feather-icon
                icon="ClockIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">创建时间</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ approvalData.time_cn }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">管理员</span>
            </th>
            <span>
              <b-badge
                v-for="manager in approvalData.managers"
                :key="manager"
                variant="light-primary"
                class="mr-1"
              >
                {{ mapUserName(manager) }}
              </b-badge>
            </span>
            <td class="pb-50 text-capitalize">
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,BBadge, BAlert
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge,BAlert
  },
  props: {
    approvalData: {
      type: Object,
      required: true,
    },
    users:{
      type: Array,
      required: true,
    }
  },
/*   computed:{
    alertContent(){
      if(this.approvalData.system_label === 'INVOICE_APPROVAL'){
        return '该审批流为系统审批流，请通过项目结算单发起'
      }
    }
  }, */
  methods:{
    deleteApproval(){
      this.$swal({
        title: '删除审批流',
        text: "删除后无法撤销，你确定要删除该审批流吗？",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '是的，删除！',
        customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
    }).then(result => {
        if (result.value) {
        this.$axios.get("/collab/approvals/del_approval/" + this.$route.params.id).then((res)=>{
        if (res.data.status == "ok"){
          this.$router.push({ name: 'collab-approvals' })
        }
        })        
        }
    })
    },
    mapUserName(userId){
      var userName
      this.users.forEach(user => {
        if (user.user_id == userId){
          userName = user.user_name
        }
      })
      return userName
    },
    launchApproval(){
      this.$emit('launch-approval')
    }
  }

}
</script>

<style>

</style>
