<template>
  <b-card title="审批流流程">
    <b-list-group>
    <b-list-group-item
      v-for="workflow in workflows"
    class="flex-column align-items-start"
    >
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1 text-white">
          {{ workflow.name }}
        </h5>
        <span>
          <b-badge variant="light-warning" v-if="workflow.mode == 'all'">所有人审批</b-badge>
          <b-badge variant="light-primary" v-else>任一人审批</b-badge>
        </span>      
      </div>

      <b-card-text class="mb-1" v-if="workflow.description">
        <span>
          <small>控件描述：</small>
          <small>{{ workflow.description }}</small>
        </span>
      </b-card-text>

      <b-card-text class="mb-1">
        <span>
          <small>审批人：</small>
          <small 
            v-if="workflows.length" 
            v-for="member in workflow.members">{{ mapUserName(member) }}
            <small v-if="workflow.members.length > 1">；</small>
          </small>
        </span>
      </b-card-text>
    </b-list-group-item>
  </b-list-group>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BMedia, BAvatar, BListGroup, BListGroupItem, BCardText, BBadge
} from 'bootstrap-vue'


export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    BListGroup, 
    BListGroupItem,
    BCardText,
    BBadge
  },
  props:{
    users: {
      type: Array,
      default: () => ([])
    },
    workflows: {
      type: Array,
      default: () => ([])
    }
  },
  methods:{
    mapUserName(userId){
      return this.users.find(user => user.user_id == userId).user_name
    }
  }
}
</script>

<style>

</style>
