<template>
  <div>
    <template>

      <approval-launch-sidebar
        :controls="approvalData.controls"
        :is-approval-launch-sidebar-active="isApprovalLaunchSidebarActive"
        @update:is-approval-launch-sidebar-active="isApprovalLaunchSidebarActive = $event"
        @get-approvals="getApprovals"
      />

      <approval-info-card
        :approval-data="approvalData" 
        :users="users" 
        @launch-approval="isApprovalLaunchSidebarActive = true"
        />

      <b-row>
        <b-col cols="12" lg="6">
          <approval-liable-card
            style="max-height:1000px; overflow:scroll"
            :users="users"
            :approval-type= "approvalData.approval_type"
            :system-label="approvalData.system_label"
            :approvals="liableApprovals"
            :workflows="approvalData.workflows"
            @get-approvals="getApprovals"/>
        </b-col>

        <b-col cols="12" lg="6">
          <approval-launched-card
            style="max-height:1000px; overflow:scroll"
            :users="users"
            :approval-type= "approvalData.approval_type"
            :system-label="approvalData.system_label"
            :approvals="launchedApprovals"
            :workflows="approvalData.workflows"
            @get-approvals="getApprovals"
            />
        </b-col>
        

      </b-row>


      <b-row class="match-height">
        <b-col cols="12" lg="6">
          <approval-template-card :controls="approvalData.controls"/>
        </b-col>

        <b-col cols="12" lg="6">
          <approval-workflow-card
            :users="users"
            :workflows="approvalData.workflows"/>
        </b-col>
      </b-row>

    </template>

  </div>
</template>

<script>
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'

import ApprovalInfoCard from './ApprovalInfoCard.vue'
import ApprovalTemplateCard from './ApprovalTemplateCard.vue'

import ApprovalLaunchedCard from './ApprovalLaunchedCard.vue'
import ApprovalLiableCard from './ApprovalLiableCard.vue'

import ApprovalWorkflowCard from './ApprovalWorkflowCard.vue'
import ApprovalLaunchSidebar from './ApprovalLaunchSidebar.vue'
export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    ApprovalInfoCard,
    ApprovalLaunchedCard,
    ApprovalLiableCard,
    ApprovalTemplateCard,
    ApprovalWorkflowCard,
    // Local Components
    ApprovalLaunchSidebar,
  },
  data(){
    return {
      approvalData: {},
      users:[],
      launchedApprovals:[],
      liableApprovals:[],
      isApprovalLaunchSidebarActive:false,
    }
  },
  methods:{
    getUsers(){
      this.$axios.get('/manage/users/get_users').then((response) => {
        this.users = response.data.data.users
      })
    },
    getLaunchedApprovals(){
      this.$axios.get('/collab/approvals/get_launched_approvals/' + this.$route.params.id).then((response) => {
        this.launchedApprovals = response.data.data.launched_approvals
      })
    },
    getLiableApprovals(){
      this.$axios.get('/collab/approvals/get_liable_approvals/' + this.$route.params.id).then((response) => {
        this.liableApprovals = response.data.data.liable_approvals
        
      })
    },
    getApprovalData(){
      this.$axios.get("collab/approvals/get_approval/" + this.$route.params.id).then(resp =>{
        this.approvalData = {...resp.data.data.approval, ...this.approvalData}
      })
    },
    getApprovals(){
      this.getLaunchedApprovals()
      this.getLiableApprovals()
    }
  },
  created(){
    this.getUsers()
    this.getApprovalData()
    this.getApprovals()
  }
}
</script>